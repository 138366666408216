<template>
  <div>
    <b-modal :active.sync="isActive" has-modal-card aria-role="dialog" aria-modal class="has-padding">
      <div class="modal-card has-text-centered">
        <section class="modal-card-body">
          <div>
            <div class="is-flex is-flex-align-center has-margin-bottom-medium">
              <faicon icon="mobile" size="4x" class="has-margin-right" />
              <h1>{{ $t('mobile.modal.title') }}</h1>
            </div>
            <div>
              <div class="has-text-centered">
                <p class="is-size-3">
                  {{ $t('mobile.modal.description') }}
                </p>
              </div>
              <div class="apps-icon-row has-margin-top-medium has-margin-bottom">
                <div></div>
                <div></div>
                <div class="has-icon">
                  <div class="logo">
                    <icon name="logo-apply" class="icon" />
                  </div>
                </div>
                <div></div>
                <div></div>
              </div>
              <div class="has-text-centered has-margin-top">
                <h3 class="is-size-2 has-margin-bottom">{{ $t('mobile.modal.subtitle') }}</h3>
                <p class="has-text-small" v-html="$t('mobile.modal.instructions')"></p>
              </div>
            </div>
          </div>
          <div class="has-margin-top-medium">
            <b-button size="is-large" type="is-text" @click="close()">{{ $t('actions.close') }}</b-button>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      isActive: this.show || false,
    }
  },
  watch: {
    show(value) {
      this.isActive = value
    },
  },
  methods: {
    close() {
      this.$emit('close', true)
      this.isActive = false
    },
  },
}
</script>
