<template>
  <div class="menu">
    <ul class="menu-list has-margin-bottom">
      <li>
        <router-link :to="to" tag="a"><faicon icon="arrow-left" /> {{ label }} </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    to: String,
    label: String,
  },
}
</script>
