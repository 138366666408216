import Vue from 'vue'
import capitalize from 'lodash/capitalize'
import numeral from 'numeral'
import dayjs from 'dayjs'
import sanitizeHtml from 'sanitize-html'

// Para usar nos métodos:
//   this.$options.filters.

Vue.filter('reverse', value => {
  return value.slice().reverse()
})

Vue.filter('currency', value => {
  if (value >= 0) {
    return '€' + value.toFixed(2).toLocaleString()
  }
  return null
})

Vue.filter('sanitizeAnyHtml', function(value) {
  return sanitizeHtml(value, { allowedTags: [] })
})

Vue.filter('formatNumber', function(value) {
  return numeral(value).format('0.0 b')
})

Vue.filter('formatPercentage', function(value) {
  return numeral(value).format('0')
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return dayjs(String(value)).format('DD/MM/YYYY')
  }
})
Vue.filter('formatMonthDate', function(value) {
  if (value) {
    return dayjs(String(value)).format('MM/YYYY')
  }
})
Vue.filter('formatDateTime', function(value) {
  if (value) {
    return dayjs(String(value)).format('DD/MM/YYYY H:mm:ss')
  }
})

Vue.filter('shortDateDaysLeft', function(deadline) {
  if (deadline) {
    const dayjsToday = dayjs()
    const dayjsDeadline = dayjs(deadline)
    return dayjsDeadline.diff(dayjsToday, 'days')
  }
})

Vue.filter('shortDateHoursLeft', function(deadline) {
  if (deadline) {
    const dayjsToday = dayjs()
    const dayjsDeadline = dayjs(deadline)
    return dayjsDeadline.diff(dayjsToday, 'hours')
  }
})

Vue.filter('formatDeadline', function(deadline) {
  if (deadline) {
    const dayjsToday = dayjs()
    const dayjsDeadline = dayjs(deadline + ' 23:59:59')

    if (dayjsToday > dayjsDeadline) {
      return deadline
    }

    const daysLeft = dayjsDeadline.diff(dayjsToday, 'days')

    // Hoje
    if (dayjsToday == dayjsDeadline) {
      return 'hoje'
    } else if (daysLeft == 1) {
      return 'amanhã'
    } else {
      return 'dentro de ' + daysLeft + ' dias'
    }
  }
})

Vue.filter('capitalize', function(value) {
  if (value) {
    return capitalize(String(value))
  }
})

Vue.filter('getExtension', function(value) {
  var regex = /(?:\.([^.]+))?$/
  return regex.exec(value)[1]
})
