/* eslint-disable no-console */

import axios from 'axios'
import Nprogress from 'nprogress'

import store from '@/store'
import router from '@/router'

export default () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URI,
    withCredentials: false,
    headers: {
      accept: 'application/json',
      'Accept-Language': store.state.language || this.$i18n.locale,
    },
  })

  // Interceta o pedido
  instance.interceptors.request.use(
    function(config) {
      store.dispatch('setApiStatus', true)
      Nprogress.start()
      return config
    },
    function(error) {
      Nprogress.done()
      return Promise.reject(error)
    }
  )

  // Interceta a resposta
  instance.interceptors.response.use(
    // Qualquer resposta com status code 200
    function(response) {
      Nprogress.done()
      return response
    },

    // Qualquer resposta com erro, onde o status code é diferente de 200
    function(error) {
      Nprogress.done()

      if (error.response) {
        console.log(error.response)

        if (error.response.status == 500) {
          router.push('/internal-error')
        }

        if (error.response.status == 401) {
          console.log('A sessão expirou. Redirecionar para login.')
          store.dispatch('logout')
        }

        return Promise.reject(error)
      }

      // Erro de ligação - servidor não responde
      else {
        store.dispatch('setApiStatus', false)
      }
    }
  )

  return instance
}
