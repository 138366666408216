import capitalize from 'lodash/capitalize'
import Api from '@/services/Api'
import ApiApply2 from '@/services/ApiApply2'
import i18n from '@/i18n'

export default {
  getApply2Procedures(type, status, code, faculty, department, org = null) {
    return ApiApply2().get('/v1/procedures', {
      params: {
        type: type,
        public_status: status,
        code: code,
        faculty: faculty,
        department: department,
        org: org,
      },
    })
  },

  getApply2Procedure(code) {
    return ApiApply2()
      .get('/v1/procedures/' + code)
      .then(response => {
        return response.data
      })
  },

  getProcedures(type, status, code, faculty, department, org = null) {
    return Api().get('/v1/procedures', {
      params: {
        type: type,
        public_status: status,
        code: code,
        faculty: faculty,
        department: department,
        org: org,
      },
    })
  },

  get(code) {
    return Api()
      .get('/v1/procedure/' + code)
      .then(response => {
        return response.data
      })
  },

  helpers: {
    getProcedureTitle(procedure, short = false) {
      if (!procedure.number_of_seats) {
        return procedure.professional_category_title || ''
      }

      const seats =
        procedure.number_of_seats_text && i18n.locale == 'pt'
          ? capitalize(procedure.number_of_seats_text)
          : procedure.number_of_seats

      let message = `${seats} `
      message += i18n.tc('position', procedure.number_of_seats)

      if (!short) message += ' ' + i18n.t('procedure.ofWork')

      if (procedure.type == 'researchers' && procedure.category_type_title) {
        message += ` ${i18n.t('to')} ${capitalize(i18n.t('researcher'))} ${procedure.category_type_title}`
      } else if (procedure.professional_category_title) {
        message += ` ${i18n.t('to')} ${procedure.professional_category_title}`
      }

      return message
    },
  },
}
