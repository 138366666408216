import Home from '@/views/Home'

import NotFound from '@/components/errors/NotFound'
import Forbidden from '@/components/errors/Forbidden'
import InternalServerError from '@/components/errors/InternalServerError'

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true },
  },

  {
    path: '/procedure/:code',
    name: 'procedure',
    component: () => import('@/views/procedure/ProcedureDetails'),
    meta: { requiresAuth: true },
  },

  {
    path: '/explore/teachers',
    name: 'explore',
    component: () => import('@/views/explore/ExploreTeachers'),
    meta: { requiresAuth: true },
  },

  {
    path: '/explore/staff',
    name: 'explore-staff',
    component: () => import('@/views/explore/ExploreStaff'),
    meta: { requiresAuth: true },
  },

  {
    path: '/explore/researchers',
    name: 'explore-researchers',
    component: () => import('@/views/explore/ExploreResearchers'),
    meta: { requiresAuth: true },
  },
  {
    path: '/explore/researchers-dl57',
    name: 'explore-researchers-dl57',
    component: () => import('@/views/explore/ExploreResearchersDL57'),
    meta: { requiresAuth: true },
  },

  {
    path: '/explore/scholarships',
    name: 'explore-scholarships',
    component: () => import('@/views/explore/ExploreScholarships'),
    meta: { requiresAuth: true },
  },

  {
    path: '/explore/leaders',
    name: 'explore-leaders',
    component: () => import('@/views/explore/ExploreLeaders'),
    meta: { requiresAuth: true },
  },

  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/Help'),
    meta: { requiresAuth: true },
  },

  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register'),
  },

  {
    path: '*',
    name: 'error',
    component: NotFound,
    meta: { requiresNoAuth: false },
  },

  {
    path: '/internal-error',
    name: 'internalError',
    component: InternalServerError,
    meta: { requiresNoAuth: false },
  },

  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
    meta: { requiresNoAuth: false },
  },
]
