<template>
  <div>
    <img v-if="photo" :src="photo" />
    <faicon v-else icon="user-circle" class="no-image"></faicon>
  </div>
</template>

<script>
export default {
  computed: {
    photo() {
      return this.$store.getters.getUserPhoto
    },
  },
}
</script>
