import axios from 'axios'
import isEmpty from 'lodash/isEmpty'

import { SnackbarProgrammatic as Snackbar } from 'buefy'

import AuthService from '@/services/AuthService'
import router from '@/router'
import UserService from '../services/UserService'

export default {
  // Define o idioma localmente
  setLanguage({ commit }, language) {
    localStorage.setItem('settings.language', language)
    commit('setLanguage', language)
  },

  // Login
  login({ commit }, { email, password, keepSignIn }) {
    return new Promise((resolve, reject) => {
      AuthService.login(email, password, keepSignIn)
        .then(response => {
          if (!response) {
            return
          }

          const stringifyUser = JSON.stringify(response.data.user) // apenas para manter o pretiier happy
          localStorage.setItem('session.token', response.data.token)
          localStorage.setItem('session.user', stringifyUser)

          axios.defaults.headers.common['Authorization'] = response.data.token

          commit('login', response.data.token)
          commit('setUser', response.data.user)

          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  // Logout
  logout({ commit }) {
    return AuthService.logout().then(() => {
      // Elimina o token local
      localStorage.removeItem('session.token')
      delete axios.defaults.headers.common['Authorization']

      // Elimina o token do store
      commit('logout')
      router.push('/login')
    })
  },

  resetLocalUser({ commit }) {
    localStorage.removeItem('session.user')
    commit('resetLocalUser')
  },

  // Obtém os dados do utilizador
  // Autenticado ou o último guardado localmente (para a janela de login)
  setUser({ commit }, reload = false, user) {
    // Valida se existe algum token registado no local storage
    const token = localStorage.getItem('session.token')

    // Caso a página tenha sida recarregada, precisamos obter os dados do utilizador autenticado
    if (token && (isEmpty(this.state.session.user) || reload)) {
      if (isEmpty(user)) {
        return AuthService.user().then(result => {
          const user = result.data

          localStorage.setItem('session.user', JSON.stringify(user))
          commit('setUser', user)

          // Obtem a foto em base64 e guarda no state
          if (user && !isEmpty(user.photo) && !this.state.session.userPhoto) {
            AuthService.getUserPhoto(user.photo.medium).then(image => {
              if (image) {
                localStorage.setItem('session.userPhoto', image)
                commit('setUserPhoto', image)
              }
            })
          }

          return user
        })
      } else {
        localStorage.setItem('session.user', JSON.stringify(user))
        commit('setUser', user)
      }
    }
  },

  setUserPhoto({ commit }, photo) {
    return AuthService.getUserPhoto(photo.medium).then(image => {
      if (image) {
        localStorage.setItem('session.userPhoto', image)
        commit('setUserPhoto', image)
      }
    })
  },

  removeUserPhoto({ commit }) {
    return UserService.deletePhoto().then(() => {
      localStorage.removeItem('session.userPhoto')
      commit('removeUserPhoto')
      return true
    })
  },

  // Mantem o estado da APi controlado,
  // para apresentar mensagem de erro, caso necessário
  setApiStatus({ commit }, isActive) {
    // Valida de se o estado da ligação é o mesmo que registado no store
    if (this.state.api.isActive != isActive) {
      commit('setApiStatus', isActive)

      // Confirma que o utilizador ainda não foi notificado
      if (!isActive) {
        Snackbar.open({
          message: this.$t('serverError'),
          type: 'is-warning',
          position: 'is-top-right',
          actionText: this.$t('actions.reload'),
          indefinite: true,
          onAction: () => {
            router.go()
          },
        })
      }
    }
  },
}
