import Vue from 'vue'
import Vuex from 'vuex'

import messages from '@/i18n'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: localStorage.getItem('settings.language') || 'pt',
    locales: messages['pt'],
    session: {
      error: false,
      errorType: false, // maxLoginAttemptsReached, InternalServerError
      errorDescription: '',
      token: localStorage.getItem('token') || '',
      longLivedToken: false,
      user: {},
      userPhoto: null,
    },
    isDownloading: 0,
    api: {
      isActive: true,
    },
  },
  mutations,
  actions,
  modules,
  getters,
})
