import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'

export default {
  isLoggedIn: state => !!state.session.token,
  getToken: state => state.session.token,
  getUser(state) {
    const localUser = JSON.parse(localStorage.getItem('session.user'))
    const user = isEmpty(state.session.user) ? localUser : state.session.user

    if (!user) return user

    // Cria atributos adicionais
    user.first_name = user.full_name ? split(user.full_name, ' ')[0] : user.full_name

    return user
  },
  getUserPhoto(state) {
    return state.session.userPhoto || localStorage.getItem('session.userPhoto')
  },
  getStateUser(state) {
    return state.session.user
  },
  getLocalUser() {
    return JSON.parse(localStorage.getItem('session.user'))
  },
  userPermissions(state) {
    return {
      isProcedureManager: state.session.user.permissions.includes('procedure-manager'),
    }
  },
}
