<template>
  <div class="default-layout">
    <Header :hide-avatar="hideAvatar" :back-to="backTo" :short-title="shortTitle">
      <template v-slot:mobile-right>
        <slot name="header-right-mobile"></slot>
      </template>
    </Header>

    <div class="main">
      <div class="inner">
        <div v-if="hasMenu" class="main-menu">
          <div class="inner">
            <div>
              <div v-if="icon" class="icon">
                <faicon :icon="icon"></faicon>
              </div>
              <BackButton v-if="backTo" :to="backTo" :label="backToLabel || $t('home')" />
              <div class="main-menu-desktop">
                <slot name="menu"></slot>
              </div>
            </div>
          </div>
        </div>
        <div class="main-content" :class="{ 'is-small': hasSmallContent }">
          <div class="inner">
            <div v-if="title" class="panel page-head" :class="{ 'with-mobile-menu': hasMobileMenu }">
              <h1 class="page-title">
                <faicon v-if="icon" class="is-hidden-desktop" :icon="icon"></faicon>
                {{ title }}
              </h1>
              <p v-if="subtitle" class="subtitle has-text-weight-normal">
                {{ subtitle }}
              </p>
              <div v-if="hasMobileMenu" class="main-menu-mobile">
                <slot name="menu"></slot>
              </div>
            </div>
            <slot name="content"></slot>
          </div>
        </div>
        <div v-if="hasSidebar" class="main-sidebar">
          <div class="inner">
            <slot name="sidebar"></slot>
          </div>
        </div>
      </div>
    </div>

    <slot v-if="hasToolbar" name="toolbar">
      <HomeToolbar />
    </slot>

    <div class="has-margin has-text-muted has-text-tiny has-text-centered">
      <div style="font-size: .7rem">
        <span class="has-text-weight-bold">UC Apply v{{ appVersion }} </span>
        <span class="has-margin">|</span>
        <span>Copyright &copy; 2021 University of Coimbra. All rights reserved.</span>
        <span class="has-margin">|</span>
        <span>Made by <a class="has-text-black" href="https://ucframework.pt" target="_blank">UC Framework</a></span>
      </div>
    </div>

    <slot name="modals"></slot>
  </div>
</template>

<script>
import Header from '@/components/Header'
import HomeToolbar from '@/components/toolbars/HomeToolbar'
import BackButton from '@/components/utilities/BackButton'

export default {
  components: {
    Header,
    HomeToolbar,
    BackButton,
  },
  props: {
    hasSmallContent: {
      default: false,
      type: Boolean,
    },
    backToLabel: {
      default: null,
      type: String,
    },
    backTo: {
      default: null,
      type: String,
    },
    backToHistory: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: null,
      type: String,
    },
    title: {
      default: null,
      type: String,
    },
    shortTitle: {
      default: '',
      type: String,
    },
    subtitle: {
      default: null,
      type: String,
    },
    hasTitle: {
      default: true,
      type: Boolean,
    },
    hasHeader: {
      default: true,
      type: Boolean,
    },
    hasMenu: {
      default: true,
      type: Boolean,
    },
    hasMobileMenu: {
      default: false,
      type: Boolean,
    },
    hasSidebar: {
      default: false,
      type: Boolean,
    },
    hasToolbar: {
      default: true,
      type: Boolean,
    },
    hideAvatar: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    }
  },
}
</script>
