export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login'),
    meta: { requiresNoAuth: true },
  },
  {
    path: '/login/recover',
    name: 'recover',
    component: () => import('@/views/auth/RecoverPassword'),
    meta: { requiresNoAuth: true },
  },
  {
    path: '/recover/password',
    name: 'recover',
    component: () => import('@/views/auth/RecoverPassword'),
    meta: { requiresNoAuth: true },
  },
  {
    path: '/login/recover/:hash',
    name: 'recover-confirm',
    component: () => import('@/views/auth/RecoverConfirm'),
    meta: { requiresNoAuth: true },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/auth/Logout'),
    meta: { requiresAuth: true },
  },
  {
    path: '/activate/:hash',
    name: 'activate-account',
    component: () => import('@/views/auth/ActivateAccount'),
    meta: { requiresNoAuth: true },
  },
]
