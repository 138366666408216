import messages from '@/i18n'

export default {
  setUser(state, user) {
    state.session.user = user
  },
  setUserPhoto(state, base64Photo) {
    state.session.userPhoto = base64Photo
  },
  removeUserPhoto(state) {
    state.session.userPhoto = ''
  },
  setLanguage(state, lang) {
    state.language = lang
    state.locales = messages[lang]
  },
  setSessionError(state, statusKey, errorDescription) {
    state.session.error = true
    state.session.errorType = statusKey
    state.session.errorDescription = errorDescription
  },
  resetLocalUser(state) {
    state.session.token = null
    state.session.user = {}
  },
  login(state, ApiToken) {
    state.session.token = ApiToken.token
    state.session.longLivedToken = ApiToken.longLivedToken
  },
  logout(state) {
    state.session.token = ''
    state.session.user = {}
  },
  setApiStatus(state, isActive) {
    state.api.isActive = isActive
  },
}
