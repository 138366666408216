<template>
  <div>
    <b-dropdown aria-role="list" position="is-bottom-left">
      <button slot="trigger" class="button" type="button">
        <div class="avatar has-margin-right-small">
          <UserPhoto />
        </div>
        <faicon icon="chevron-down" size="xs" />
      </button>
      <b-dropdown-item custom aria-role="menuitem">
        <div class="has-text-weight-bold">
          {{ user.full_name }}
        </div>
        <div class="has-text-small">{{ user.email }}</div>
      </b-dropdown-item>
      <hr class="dropdown-divider" />
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/account" tag="a">
          {{ $t('account') }}
        </router-link>
        <router-link to="/support" tag="a">
          {{ $t('support.title') }}
        </router-link>
        <router-link to="/logout" tag="a"><faicon icon="sign-out-alt"></faicon> {{ $t('login.signout') }}</router-link>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import UserPhoto from '@/components/user/UserPhoto'
export default {
  components: {
    UserPhoto,
  },
  props: {
    hideAvatar: Boolean,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
    }
  },
}
</script>
