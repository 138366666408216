export default [
  {
    path: '/manage/support/queue/:key',
    name: 'manage-support-queue',
    component: () => import('@/views/manage/support/Support'),
    meta: { requiresAuth: true },
  },
  {
    path: '/manage/support/issue/:key',
    name: 'manage-support-issue',
    component: () => import('@/views/manage/support/Issue'),
    meta: { requiresAuth: true },
  },
]
