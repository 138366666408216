/* eslint-disable no-console */

import Api from '@/services/Api'

export default {
  getProcedureTypes() {
    return Api()
      .get('/v1/procedure-types')
      .then(response => {
        return response.data
      })
      .catch(err => {
        console.log(err)
      })
  },

  getOrganicUnits() {
    return Api()
      .get('/v1/organic-units')
      .then(response => {
        return response.data
      })
      .catch(err => {
        console.log(err)
      })
  },

  getCountries() {
    return Api()
      .get('/v1/countries')
      .then(response => {
        return response.data
      })
      .catch(err => {
        console.log(err)
      })
  },

  getPhoneCountries() {
    return Api()
      .get('/v1/phone-countries')
      .then(response => {
        return response.data
      })
      .catch(err => {
        console.log(err)
      })
  },

  getOption(key) {
    return Api().get('/v1/option/' + key)
  },

  setOption(key, value) {
    return Api(key).post('/v1/option/' + key, { value })
  },
}
