import endsWith from 'lodash/endsWith'
import Api from '@/services/Api'

export default {
  login(email, password, keepSignIn) {
    return Api().post('/v1/auth/login', {
      email: email,
      password: password,
      longLivedToken: keepSignIn,
    })
  },

  recover(email) {
    return Api().post('/v1/auth/recover/password', {
      email: email,
    })
  },

  recoverConfirm(hash, password, confirmPassword) {
    return Api().post('/v1/auth/recover/password/' + hash, {
      password: password,
      confirm_password: confirmPassword,
    })
  },

  register(email, password, full_name) {
    return Api().post('/v1/auth/register', {
      full_name: full_name,
      password: password,
      email: email,
    })
  },

  activate(hash) {
    return Api().post('/v1/auth/activate/' + hash, {
      accept: true,
    })
  },

  logout() {
    return Api().delete('/v1/auth/logout')
  },

  user() {
    return Api().get('/v1/user/me')
  },

  getUserPhoto(url) {
    return Api()
      .get(url, {
        responseType: 'arraybuffer',
      })
      .then(
        response =>
          `data:${response.headers['content-type']};base64,${btoa(
            String.fromCharCode(...new Uint8Array(response.data))
          )}`
      )
  },

  helpers: {
    externalInstitutionalDomains: ['deq.uc.pt', 'dei.uc.pt', 'deec.uc.pt', 'mat.uc.pt'],

    isExternalInstitutionalEmail(email) {
      let notAllowedDomain = false
      this.externalInstitutionalDomains.forEach(domain => {
        if (endsWith(email, domain)) {
          notAllowedDomain = true
        }
      })

      if (notAllowedDomain) {
        return true
      } else {
        return false
      }
    },
    isInstitutionalEmail(email) {
      if (!this.isExternalInstitutionalEmail(email) && (endsWith(email, '@uc.pt') || endsWith(email, '.uc.pt'))) {
        return true
      } else {
        return false
      }
    },
  },
}
