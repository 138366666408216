<template>
  <div class="menu">
    <div v-if="user.permissions.length">
      <p class="menu-label">{{ $t('admin.title') }}</p>
      <nav>
        <ul class="menu-list">
          <li class="is-base">
            <a :href="getManageUrl()" class="is-active">
              <faicon icon="external-link-alt"></faicon>
              Interface de gestão para membro de júri
            </a>
          </li>
          <li>
            <a @click="$router.push('/manage/support/queue/unresolved')">
              <faicon icon="life-ring"></faicon>
              {{ $t('admin.helpdesk') }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <p class="menu-label">{{ $t('myOptions') }}</p>
    <scrollactive>
      <ul class="menu-list">
        <li>
          <router-link to="/applications"
            ><faicon icon="cube"></faicon>
            <v-clamp autoresize :max-lines="1">{{ $tc('application.label', 2) }}</v-clamp>
          </router-link>
        </li>
        <li>
          <router-link to="/account"
            ><faicon icon="user-circle"></faicon>
            <v-clamp autoresize :max-lines="1">{{ $t('account') }}</v-clamp>
          </router-link>
        </li>
        <li>
          <router-link to="/support"
            ><faicon icon="concierge-bell"></faicon>
            <v-clamp autoresize :max-lines="1">{{ $t('support.title') }}</v-clamp>
          </router-link>
        </li>
      </ul>
    </scrollactive>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.getUser
    },
  },
  methods: {
    getManageUrl() {
      if (process.env.VUE_APP_IS_STAGING == 'true') {
        return 'https://apply.dev.ucframework.pt/manage'
      } else {
        return 'https://apply.uc.pt/manage'
      }
    },
  },
}
</script>
