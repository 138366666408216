import Api from '@/services/Api'

export default {
  user() {
    return Api().get('/v1/user/me')
  },

  update(user) {
    return Api()
      .post('/v1/user/me', user)
      .then(response => {
        return response.data
      })
  },

  deletePhoto() {
    return Api().delete('/v1/user/me/photo')
  },
}
