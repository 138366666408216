import Vue from 'vue'
import VueRouter from 'vue-router'

import isEmpty from 'lodash'
import Nprogress from 'nprogress'

import store from '@/store'
import BaseRoutes from './routes/base'
import CandidateRoutes from './routes/candidate'
import AuthRoutes from './routes/auth'
import UserRoutes from './routes/user'
import SupportRoutes from './routes/support'
import ManageRoutes from './routes/manage'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE,
  routes: [].concat(BaseRoutes, CandidateRoutes, AuthRoutes, UserRoutes, SupportRoutes, ManageRoutes),
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    Nprogress.start()
  }
  next()
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  Nprogress.done()
})

router.beforeEach((to, from, next) => {
  // Valida se existe algum token registado no local storage
  const token = localStorage.getItem('session.token')
  if (token) {
    store.state.session.token = token
  }

  // Valida vistas que obrigam a um utilizador autenticado
  // Caso não aconteça, redirecionar o utilizador para a página de login
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      // Obtém info do utilizador autenticado
      // Caso os dados não existam localmente, realiza novo pedido à API
      if (token && isEmpty(store.state.session.user)) {
        store.dispatch('setUser')
      }
      next()
      return
    }
    next('/login')
  }

  // Valida as vistas que não permitam utilizadores autenticados
  // Normalmente, apenas a vista de login, registo e recuperação de password nesta acl.
  // Caso aconteça, redireciona para a home.
  else if (to.matched.some(record => record.meta.requiresNoAuth)) {
    if (store.getters.isLoggedIn) {
      next('/')
      return
    }
    next()
  } else {
    next()
  }
})

export default router
