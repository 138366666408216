import Api from '@/services/Api'
import store from '@/store'

export default {
  add(procedureKey) {
    return Api()
      .post('/v1/application/' + procedureKey + '/start')
      .then(response => {
        return response.data
      })
  },

  get(procedureKey) {
    return Api()
      .get('/v1/application/' + procedureKey)
      .then(response => {
        return response.data
      })
      .catch(err => {
        if (err.response.status == 400) {
          return err.response.data
        }
      })
  },

  update(application) {
    return Api().post('/v1/application/' + application.code, application)
  },

  async addListEntry(applicationCode, listKey, entry) {
    const response = { items: null, errors: null }
    const apiResponse = await Api().post('/v1/application/' + applicationCode + '/list/' + listKey, entry)
    if (apiResponse.data.added) {
      response.items = apiResponse.data.items
    } else {
      response.errors = apiResponse.data.__errors__
    }
    return response
  },

  async deleteListEntry(applicationCode, listKey, entryKey) {
    const response = await Api().delete('/v1/application/' + applicationCode + '/list/' + listKey + '/' + entryKey)
    return response.data
  },

  getAll() {
    return Api()
      .get('/v1/applications')
      .then(response => {
        return response.data
      })
  },

  deleteFile(fileKey) {
    return Api()
      .delete('/v1/application/file/' + fileKey)
      .then(response => {
        return response.data
      })
  },

  updateFile(file) {
    return Api()
      .post('/v1/application/file/' + file.key, {
        title: file.title,
        reserved: file.reserved,
      })
      .then(response => {
        return response.data
      })
  },

  downloadFile(fileKey, fileName) {
    return Api()
      .get('/v1/self-application/' + fileKey + '/' + fileName, {
        responseType: 'blob',
        onDownloadProgress(progressEvent) {
          store.state.isDownloading = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          // console.log(progressEvent.lengthComputable)
        },
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
  },

  helpers: {
    getStatusMap(status) {
      const map = {
        draft: 'Em rascunho',
        submitted: 'Submetida',
        cancelled: 'Cancelada',
      }
      return map[status]
    },
  },
}
