<template>
  <header class="header">
    <div class="inner-mobile">
      <div class="header-left">
        <slot name="mobile-left">
          <router-link v-if="!backTo" to="/">
            <faicon icon="star-of-life"></faicon>
          </router-link>
          <router-link v-else :to="backTo">
            <faicon icon="arrow-left"></faicon>
          </router-link>
        </slot>
      </div>

      <div class="header-center start-hidden">
        <div class="app-icon" :class="{ 'hide-on-scroll': shortTitle }">
          <router-link to="/">
            <icon name="logo-apply" class="icon" />
          </router-link>
        </div>
        <slot name="mobile-center">
          <v-clamp v-if="shortTitle" autoresize :max-lines="2">{{ shortTitle }}</v-clamp>
        </slot>
      </div>

      <div class="header-right">
        <slot name="mobile-right"></slot>
      </div>
    </div>
    <div class="inner-desktop">
      <div class="header-left">
        <div class="app-icon">
          <router-link to="/">
            <icon name="logo-apply" class="icon" />
          </router-link>
        </div>
      </div>

      <div class="header-center">
        <div v-cloak class="brand-icon">
          <img :src="assetsPath + 'img/minerva1290.png'" />
        </div>
      </div>

      <div class="header-right">
        <div class="nav-languages">
          <LanguageNav />
        </div>
        <div class="nav-session">
          <SessionNav :hide-avatar="hideAvatar" />
        </div>
        <div class="nav-button right">
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LanguageNav from '@/components/LanguageNav'
import SessionNav from '@/components/SessionNav'
import VClamp from 'vue-clamp'

export default {
  components: {
    LanguageNav,
    SessionNav,
    VClamp,
  },
  props: {
    shortTitle: {
      default: null,
      type: String,
    },
    backTo: {
      default: null,
      type: String,
    },
    hideAvatar: {
      default: false,
      type: Boolean,
    },
    hasLogo: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      assetsPath: process.env.VUE_APP_ROUTER_BASE,
    }
  },
}
</script>
