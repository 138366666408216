import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './locale/en.json'
import pt from './locale/pt.json'

Vue.use(VueI18n)

const messages = {
  en,
  pt,
}

// Inicializar i18n
const i18n = new VueI18n({
  locale: localStorage.getItem('settings.language') || 'pt',
  fallbackLocale: 'pt',
  messages,
})

export default i18n
