<template>
  <div>
    <b-modal :active.sync="isActive" :width="640" scroll="keep" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body">
          <b-carousel
            v-model="slide"
            :arrow="arrow"
            :indicator="indicator"
            :indicator-inside="indicatorInside"
            :indicator-style="indicatorStyle"
            :autoplay="false"
          >
            <b-carousel-item>
              <div class="is-flex is-flex-align-center has-margin-bottom-medium">
                <faicon icon="cube" size="2x" class="has-margin-right" />
                <h1>Apply UC</h1>
              </div>

              <div>
                <div>
                  <p class="is-size-2">
                    A Apply UC foi criada para facilitar a gestão dos procedimentos concursais a decorrer na
                    Universidade de Coimbra. Através desta plataforma, poderá gerir as suas candidaturas ou o seu
                    trabalho de júri em procedimentos existentes.
                  </p>
                </div>
                <div class="has-margin-top has-text-right">
                  <b-button type="is-text" size="is-large" @click="slide = 1">{{ $t('actions.next') }}</b-button>
                </div>
              </div>
            </b-carousel-item>
            <b-carousel-item>
              <div class="is-flex is-flex-align-center has-margin-bottom-medium">
                <faicon icon="cube" size="4x" class="has-margin-right" />
                <h1>Para começar...</h1>
              </div>

              <p class="has-margin-bottom-medium is-size-2">
                Procure no menu <strong>Explorar</strong> o procedimento ao qual se pretende candidatar. Se o prazo de
                candidaturas ainda estiver aberto, basta carregar em
                <strong>{{ $t('applicationState.start') }}</strong> e seguir as instruções de preenchimento.
              </p>
              <p>
                Para garantir a melhor experiência de utilização, por favor,
                <strong>utilize um browser atualizado.</strong>
              </p>
              <p class="has-margin-top-medium has-margin-bottom has-text-right">
                <b-button class="is-hidden-mobile" size="is-large" type="is-primary" @click="isActive = false"
                  >Começar a explorar</b-button
                >
                <b-button class="is-hidden-tablet" type="is-text" size="is-large" @click="slide = 2">{{
                  $t('actions.next')
                }}</b-button>
              </p>
            </b-carousel-item>
            <b-carousel-item class="is-hidden-tablet">
              <div class="is-flex is-flex-align-center has-margin-bottom-medium">
                <faicon icon="mobile" size="3x" class="has-margin-right" />
                <h1>No seu telefone</h1>
              </div>
              <div>
                <div>
                  <p class="is-size-4">
                    Preparámos cuidadosamente a nossa plataforma para uma ótima experiência de utilização no seu
                    smartphone. Através da utilização de uma nova tecnologia, esta plataforma web pretende comportar-se
                    de forma semelhante a uma aplicação nativa.
                  </p>
                </div>
                <div class="apps-icon-row has-margin-top-medium has-margin-bottom">
                  <div></div>
                  <div></div>
                  <div class="has-icon">
                    <div class="logo">
                      <icon name="logo-apply" class="icon" />
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                </div>
                <div class="has-text-centered has-margin-top">
                  <h3 class="is-size-2 has-margin-bottom">Instale a Apply UC</h3>
                  <p class="has-text-small">
                    Procure a opção <strong>Adicionar ao Ecrã principal</strong> no seu browser, disponível em
                    <strong>Menu</strong> no seu Android (Google) ou na opção <strong>Partilhar</strong> no seu
                    dispositivo iOS (Apple).
                  </p>
                </div>
                <div class="has-margin-top-medium has-margin-bottom has-text-centered">
                  <b-button size="is-large" expanded type="is-primary" @click="isActive = false">{{
                    $t('actions.signin')
                  }}</b-button>
                </div>
              </div>
            </b-carousel-item>
          </b-carousel>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      isActive: this.show || false,
      slide: 0,
      arrow: false,
      indicator: false,
      indicatorInside: true,
      indicatorStyle: 'is-lines',
    }
  },
  watch: {
    show(value) {
      this.isActive = value
    },
  },
}
</script>
