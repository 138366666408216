import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

import VueScrollactive from 'vue-scrollactive'
import VClamp from 'vue-clamp'

import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'

import SvgIcon from 'vue-svgicon'
import Buefy from 'buefy'
import Vuelidate from 'vuelidate'
import Nprogress from 'nprogress'

import '@/filters'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons/faGlobeEurope'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import { faCompass } from '@fortawesome/free-solid-svg-icons/faCompass'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faSadTear } from '@fortawesome/free-solid-svg-icons/faSadTear'
import { faSadCry } from '@fortawesome/free-solid-svg-icons/faSadCry'
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons/faHeartBroken'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity'
import { faIdBadge } from '@fortawesome/free-solid-svg-icons/faIdBadge'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft'
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends'
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment'
import { faCube } from '@fortawesome/free-solid-svg-icons/faCube'
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons/faStarOfLife'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons/faCheckDouble'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons/faConciergeBell'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons/faCloudDownloadAlt'
import { faCubes } from '@fortawesome/free-solid-svg-icons/faCubes'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle'
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons/faPauseCircle'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faUserClock } from '@fortawesome/free-solid-svg-icons/faUserClock'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt'
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faUnlock } from '@fortawesome/free-solid-svg-icons/faUnlock'
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons/faFlagCheckered'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons/faCalendarPlus'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons/faBoxOpen'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faHashtag } from '@fortawesome/free-solid-svg-icons/faHashtag'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faMobile } from '@fortawesome/free-solid-svg-icons/faMobile'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons/faLifeRing'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'

library.add(
  faMinus,
  faTrashAlt,
  faLifeRing,
  faSignOutAlt,
  faLongArrowAltRight,
  faSearch,
  faLayerGroup,
  faChevronDown,
  faChevronRight,
  faGlobeEurope,
  faHome,
  faUserCircle,
  faCompass,
  faInfoCircle,
  faArrowLeft,
  faArrowRight,
  faSadTear,
  faSadCry,
  faHeartBroken,
  faUniversity,
  faIdBadge,
  faLongArrowAltLeft,
  faArrowAltCircleLeft,
  faArrowCircleLeft,
  faCheckSquare,
  faUserFriends,
  faBook,
  faInfoCircle,
  faQuestionCircle,
  faComment,
  faCube,
  faStarOfLife,
  faPlus,
  faSignOutAlt,
  faCheckDouble,
  faFilter,
  faConciergeBell,
  faExternalLinkAlt,
  faHashtag,
  faCloudDownloadAlt,
  faCubes,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faPlayCircle,
  faPauseCircle,
  faTimesCircle,
  faCheckCircle,
  faAngleLeft,
  faAngleRight,
  faUserClock,
  faFilePdf,
  faFileAlt,
  faImage,
  faLock,
  faUnlock,
  faFlagCheckered,
  faCheck,
  faCalendarPlus,
  faBoxOpen,
  faClock,
  faCheckCircle,
  faEdit,
  faPhone,
  faEnvelope,
  faMobile,
  faCaretDown
)
Vue.component('faicon', FontAwesomeIcon)

Vue.component('v-clamp', VClamp)

Vue.use(Buefy, {
  defaultIconComponent: 'faicon',
  defaultIconPack: 'fas',
})

Vue.use(VueI18n)
Vue.use(SvgIcon, { tagName: 'icon' })
Vue.use(Vuelidate)
Vue.use(Nprogress)
Vue.use(VueScrollactive)

Nprogress.configure({ showSpinner: false, minimum: 0.1 })

Vue.config.productionTip = false

// Define a autorização, caso exista
Vue.prototype.$http = axios
const token = localStorage.getItem('session.token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
