<template>
  <div id="app" :class="{ scrolled: mainScrolled }">
    <router-view />
  </div>
</template>

<style lang="scss">
@import '@/styles/main';
</style>

<script>
import 'typeface-roboto'

// Importa os icones necessários
import '@/assets/compiled-svg/logo-apply'
import '@/assets/compiled-svg/bold'
import '@/assets/compiled-svg/redo'
import '@/assets/compiled-svg/undo'
import '@/assets/compiled-svg/hr'
import '@/assets/compiled-svg/code'
import '@/assets/compiled-svg/quote'
import '@/assets/compiled-svg/ul'
import '@/assets/compiled-svg/ol'
import '@/assets/compiled-svg/paragraph'
import '@/assets/compiled-svg/italic'
import '@/assets/compiled-svg/underline'
import '@/assets/compiled-svg/fa-globe-europe'

export default {
  data() {
    return {
      mainScrolled: false,
      refreshing: false,
      registration: null,
      updateExists: false,
      appVersion: process.env.VUE_APP_VERSION,
    }
  },

  created() {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },

  beforeDestroy() {
    window.addEventListener('scroll', this.updateScroll)
  },

  methods: {
    updateScroll() {
      if ((window.pageYOffset || document.documentElement.scrollTop) > 25) {
        this.mainScrolled = true
      } else {
        this.mainScrolled = false
      }
    },

    showRefreshUI(e) {
      this.registration = e.detail
      this.updateExists = true

      this.$buefy.dialog.confirm({
        type: 'is-primary',
        message: this.$t('newVersion'),
        indefinite: true,
        queue: false,
        confirmText: 'Atualizar',
        canCancel: false,
        onConfirm: () => {
          this.refreshApp()
        },
      })
    },

    refreshApp() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    },
  },
}
</script>
