export default [
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/account/Account'),
    meta: { requiresAuth: true },
  },

  {
    path: '/account/personal',
    name: 'account-personal-data',
    component: () => import('@/views/account/PersonalData'),
    meta: { requiresAuth: true },
  },

  {
    path: '/account/password',
    name: 'account-change-password',
    component: () => import('@/views/account/ChangePassword'),
    meta: { requiresAuth: true },
  },

  {
    path: '/applications',
    name: 'applications',
    component: () => import('@/views/candidate/Applications'),
    meta: { requiresAuth: true },
  },
]
