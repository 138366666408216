<template>
  <a class="record is-link" @click="$router.push('/application/' + item.code + '/view')">
    <div class="record-icon">
      <faicon icon="cube"></faicon>
    </div>
    <div class="record-content">
      <div class="record-header is-flex has-margin-bottom-tiny">
        <div>{{ item.procedure.published_dr_date }}</div>
        <div>
          <span
            v-if="item.procedure.status == 'published' && getEndDayKey() == 'today'"
            class="has-text-weight-bold has-text-danger has-margin-right"
            >{{ $t('applicationState.endsToday') }}</span
          >
          <span
            v-if="item.procedure.status == 'published' && getEndDayKey() == 'tomorrow'"
            class="has-text-weight-bold has-margin-right"
            >{{ $t('endsTomorrow') }}</span
          >
          <span v-if="item.procedure.status == 'published' && getEndDayKey() == 'other' && item.publish_end">
            {{ $t('applicationState.endsIn') }} {{ item.publish_end | formatDate }}
          </span>
          <span v-if="item.procedure.status == 'published' && getEndDayKey() == 'ended'">{{
            $t('applicationState.closed')
          }}</span>
        </div>
      </div>
      <div class="record-title is-flex">
        <b-tag
          class="has-margin-right-small"
          :class="{
            'is-primary': item.status == 'submitted',
            'is-warning': item.status == 'draft',
            'is-black': item.status == 'cancelled',
          }"
        >
          {{ getItemStatusDescription(item.status) }}
        </b-tag>
        <span class="is-size-4 has-text-weight-bold is-flex-grow">{{ $tc('application.label') }} {{ item.code }}</span>
      </div>
      <div class="record-meta has-margin-top-small">
        {{ getProcedureTitle(true) }}
      </div>
      <div class="record-meta">
        <v-clamp v-if="item.procedure.area" autoresize :max-lines="1">
          {{ item.procedure.area }} {{ item.procedure.sub_area ? ' - ' + item.procedure.sub_area : '' }} -
          {{ item.procedure.faculty_title }}
          {{ item.procedure.department_title ? ' - ' + item.procedure.department_title : '' }}
        </v-clamp>
      </div>
    </div>
  </a>
</template>

<script>
import dayjs from 'dayjs'
import ProcedureService from '@/services/ProcedureService'

export default {
  props: {
    item: Object,
  },
  methods: {
    getEndDayKey() {
      const today = dayjs().format('YYYY-MM-DD')
      const tomorrow = dayjs(today)
        .add(1, 'days')
        .format('YYYY-MM-DD')
      const end = dayjs(String(this.item.procedure.publish_end)).format('YYYY-MM-DD')

      if (end == today) {
        return 'today'
      } else if (end == tomorrow) {
        return 'tomorrow'
      } else if (end < today) {
        return 'ended'
      } else {
        return 'other'
      }
    },
    getProcedureTitle(short) {
      return ProcedureService.helpers.getProcedureTitle(this.item.procedure, short)
    },
    getItemStatusDescription(status) {
      const map = {
        draft: this.$t('draft'),
        cancelled: this.$t('cancelled'),
        submitted: this.$t('submitted'),
      }
      return map[status]
    },
  },
}
</script>
