<template>
  <MainLayout :title="$t('home')" hide-avatar has-sidebar :has-title="false">
    <template v-slot:menu>
      <UserMenu />
      <ExploreMenu />
    </template>
    <template v-slot:content>
      <div class="box has-margin-top has-margin-bottom-large">
        <div class="hero-user horizontal">
          <div class="hero-avatar">
            <AccountAvatar></AccountAvatar>
          </div>
          <div class="hero-content has-margin-top">
            <p class="is-size-2">{{ humanizedTimeOfDay }}, {{ user.first_name }}.</p>
            <p class="email">{{ user.email }}</p>
            <div class="hero-nav">
              <a class="button is-text is-small" @click="$router.push('/account')">{{ $t('app.settings') }}</a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!loading && !applications.length" class="box has-text-centered has-margin-top-large">
        <div class="hero-icon">
          <faicon icon="box-open" size="3x"></faicon>
        </div>
        <div class="hero-content has-margin-top">{{ $t('noApplications') }}.</div>
        <div class="hero-content lead has-margin">
          Para iniciar, procure o procedimento na secção Explorar.
        </div>
        <div class="hero-nav">
          <router-link class="is-primary button" to="/explore/teachers" tag="button"
            ><faicon icon="compass"></faicon> {{ $t('procedure.explore') }}
          </router-link>
        </div>
      </div>

      <div v-else class="panel">
        <!-- Lista de candidaturas -->
        <div class="panel-header">
          <div class="panel-header-title is-flex">
            <h2 class="is-size-4">{{ $t('myApplications') }}</h2>
          </div>
        </div>
        <div class="panel-body">
          <ContentLoader v-if="loading" type="row" />
          <RecordApplication
            v-for="application in applications.slice().reverse()"
            v-else
            :key="application.code"
            :is-application="true"
            :item="application"
          />
        </div>
      </div>
    </template>
    <template v-slot:sidebar>
      <div>
        <div class="has-margin-top-large">
          <h2>{{ $t('support.needHelp') }}?</h2>
          <div>
            <a class="is-size-4" @click="$router.push('/help')">{{ $t('support.faqs') }}</a>
          </div>
          <div class="has-margin-top has-text-small">
            {{ $t('support.askHelp') }}
            <router-link class="has-text-weight-bold is-lowercase" to="/support">{{ $t('support.title') }}</router-link
            >.
          </div>
        </div>
        <hr />
        <div v-if="isAdBoxMobileAddToHomeActive" class="has-margin-top-large">
          <div class="is-flex">
            <div>
              <faicon icon="mobile" size="2x" class="has-margin-right" />
            </div>
            <div>
              <p>{{ $t('mobile.description') }}</p>
              <p>
                <a @click="isAddToHomeModalActive = true">
                  {{ $t('knowMore') }}
                </a>
              </p>
              <p>
                <a class="has-text-tiny has-text-muted" @click="closeMobileInfo">
                  {{ $t('actions.hide') }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modals>
      <ModalIntro :show.sync="isIntroModalActive" />
      <ModalAddToHome :show.sync="isAddToHomeModalActive" @close="isAddToHomeModalActive = false"></ModalAddToHome>
    </template>
  </MainLayout>
</template>

<script>
import dayjs from 'dayjs'

import MainLayout from '@/components/layouts/MainLayout'

import ContentLoader from '@/components/utilities/ContentLoader'
import ApplicationService from '@/services/ApplicationService'
import UtilitiesService from '@/services/UtilitiesService'

import AccountAvatar from '@/components/account/AccountAvatar'
import UserMenu from '@/components/menus/UserMenu'
import ExploreMenu from '@/components/menus/ExploreMenu'
import RecordApplication from '@/components/records/RecordApplication'
import ModalIntro from '@/components/modals/ModalIntro'
import ModalAddToHome from '@/components/modals/ModalAddToHome'

export default {
  components: {
    MainLayout,
    UserMenu,
    ExploreMenu,
    RecordApplication,
    ModalIntro,
    ModalAddToHome,
    ContentLoader,
    AccountAvatar,
  },
  data() {
    return {
      loading: false,
      applications: [],
      isIntroModalActive: false,
      isAddToHomeModalActive: false,
      isAdBoxMobileAddToHomeActive: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    humanizedTimeOfDay() {
      const currentHour = dayjs().format('HH')
      return currentHour < 12
        ? this.$t('salutation.morning')
        : currentHour <= 17
        ? this.$t('salutation.afternoon')
        : this.$t('salutation.evening')
    },
  },
  mounted() {
    this.checkModalIntro()
    this.checkMobileInfo()
    this.getApplications()
  },
  methods: {
    checkModalIntro() {
      this.startIntro()
    },

    // Obtém as candidaturas do utilizador autenticado
    async getApplications() {
      this.loading = true
      this.applications = await ApplicationService.getAll()
      this.loading = false
    },

    checkMobileInfo() {
      if (!localStorage.getItem('adbox:mobile-add-to-home')) {
        this.isAdBoxMobileAddToHomeActive = true
      } else {
        this.isAdBoxMobileAddToHomeActive = false
      }
    },

    closeMobileInfo() {
      localStorage.setItem('adbox:mobile-add-to-home', 'hidden')
      this.isAdBoxMobileAddToHomeActive = false
    },

    // Modal de boas-vindas
    startIntro() {
      const optionIntro = 'intro-v1'
      UtilitiesService.getOption(optionIntro).then(response => {
        if (!response.data) {
          UtilitiesService.setOption(optionIntro, true)
          setTimeout(() => {
            this.isIntroModalActive = true
          }, 1500)
        }
      })
    },

    // // Modal a informar dos prazos suspensos por causa do coronavirus
    // startSuspendedWarning() {
    //   const optionIntro = 'corona-suspended-deadline-v10'
    //   UtilitiesService.getOption(optionIntro).then(response => {
    //     if (!response.data) {
    //       UtilitiesService.setOption(optionIntro, true)
    //       setTimeout(() => {
    //         this.isModalSuspendedActive = true
    //       }, 850)
    //     }
    //   })
    // },
  },
}
</script>
