<template>
  <div>
    <div v-if="showEdit">
      <Uploader
        reference-id="avatar"
        allowed="images"
        :is-docked="true"
        :is-custom="true"
        :clear-after="true"
        :limit="1"
        :path-to-upload="'/v1/user/me/photo'"
        @upload="updateAvatar"
      >
        <div slot="navbar">
          <div class="avatar">
            <UserPhoto />
          </div>
          <button class="button is-text is-small">{{ $t('actions.edit') }}</button>
          <span class="instructions is-marginless">{{ $t('user.photoUpload') }}</span>
        </div>
      </Uploader>
      <div class="has-text-centered">
        <button v-if="userPhoto" class="button is-text is-small" @click="deletePhoto">
          {{ $t('user.photoDelete') }}
        </button>
      </div>
    </div>
    <div v-else>
      <div class="avatar">
        <UserPhoto />
      </div>
    </div>
  </div>
</template>

<script>
import Uploader from '@/components/utilities/Uploader'
import UserPhoto from '@/components/user/UserPhoto'

export default {
  components: {
    Uploader,
    UserPhoto,
  },
  props: {
    showEdit: Boolean,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
    }
  },
  computed: {
    userPhoto() {
      return this.$store.getters.getUserPhoto
    },
  },
  methods: {
    async updateAvatar() {
      const user = await this.$store.dispatch('setUser', true)
      if (user && user.photo && user.photo.medium) {
        this.$store.dispatch('setUserPhoto', user.photo)
      }
    },
    async deletePhoto() {
      const photoDeleted = await this.$store.dispatch('removeUserPhoto')
      if (photoDeleted) {
        this.$buefy.snackbar.open({
          message: this.$t('user.photoDeleted'),
          type: 'is-primary',
          position: 'is-bottom-right',
          duration: 5000,
        })
      }
    },
  },
}
</script>
