export default [
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/support/Support'),
    meta: { requiresAuth: true },
  },

  {
    path: '/support/issue/:key',
    name: 'support-issue',
    component: () => import('@/views/support/Issue'),
    meta: { requiresAuth: true },
  },
]
